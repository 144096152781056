import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faCommentDots } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageSection from '../components/page-section';
import Button from '../components/button';
import Carousel from '../components/carousel';
import AccreditationLogo from '../components/accreditation-logo';

import carouselData from '../data/index-carousel.json';

import './index.scss';

const seo = {
    title: 'Home',
    description: `Home page for JPF Solutions Ltd. We are a Yorkshire-based family run business providing services across multiple trades. JPF Solutions have you covered with honest and fair pricing, with all work done to a professional standard.`,
};

const getImageData = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "carousel/index" } }) {
                edges {
                    node {
                        childImageSharp {
                            fluid(maxWidth: 400, quality: 70) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        relativePath
                    }
                }
            }
        }
    `);

    return data.allFile.edges.reduce((acc, edge) => {
        acc[edge.node.relativePath] = edge.node;

        return acc;
    }, {});
};

const IndexPage = () => {
    const carouselImagesBySrc = getImageData();

    return (
        <Layout pageId="home" title="Home" layoutClassName="home">
            <SEO
                title={seo.title}
                description={seo.description}
                relativeCanonicalSlug=""
            />

            <div className="content">
                <PageSection className="intro">
                    <div className="page-section__text page-section__text--right">
                        <p className="page-section__tagline">
                            Providing services you can trust throughout
                            Yorkshire and the surrounding areas
                        </p>
                        <p className="page-section__sub-tagline">
                            We are a Yorkshire-based family run business
                            providing services across multiple trades. JPF
                            Solutions have you covered with{' '}
                            <span className="bold">honest</span> and{' '}
                            <span className="bold">fair</span> pricing, with all
                            work done to a{' '}
                            <span className="bold">professional standard</span>.
                        </p>
                        <p className="page-section__sub-tagline">
                            We work on small to large-scale projects on{' '}
                            <span className="bold">residential</span>,{' '}
                            <span className="bold">commercial</span> and{' '}
                            <span className="bold">industrial</span> properties.
                        </p>
                    </div>
                    <div className="page-section__image intro__carousel-container">
                        <div className="intro__carousel">
                            <Carousel uniqueCarouselName="index-carousel-1">
                                {carouselData
                                    .map((imageData, ix) => ({
                                        ...imageData,
                                        key: ix + 1,
                                    }))
                                    .map(carouselItem => (
                                        <div
                                            className="glide__image-container"
                                            key={carouselItem.key}
                                        >
                                            <Img
                                                fluid={
                                                    carouselImagesBySrc[
                                                        carouselItem.src
                                                    ].childImageSharp.fluid
                                                }
                                                loading="eager"
                                                className="glide__image"
                                                alt={carouselItem.alt}
                                            />
                                        </div>
                                    ))}
                            </Carousel>
                        </div>
                    </div>
                </PageSection>

                <PageSection className="accreditations">
                    <div className="page-section__image accreditations__logos">
                        <div className="accreditations__logo-group">
                            <div className="accreditations__logo-container accreditations__logo-container--napit">
                                <AccreditationLogo accreditationName="napit" />
                            </div>
                            <div className="accreditations__logo-container accreditations__logo-container--trust-mark">
                                <AccreditationLogo accreditationName="trustMark" />
                            </div>
                        </div>
                    </div>
                    <div className="page-section__text">
                        <p className="page-section__tagline">
                            We are accredited by some of the major electrical
                            safety organisations
                        </p>
                        <p className="page-section__sub-tagline">
                            This means you can trust us to do a good job, and
                            that our work and practices are always up to a
                            professional and regulated standard.
                        </p>
                    </div>
                </PageSection>

                <PageSection className="links">
                    <div className="links__link links__link--grey">
                        <div className="links__inner">
                            <div className="links__icon">
                                <FontAwesomeIcon icon={faTools} />
                            </div>
                            <div className="links__question">
                                Want to see more of what we can do and what
                                services we offer?
                            </div>
                            <div className="links__button-container">
                                <Button text="Services" to="services" />
                            </div>
                        </div>
                    </div>
                    <div className="links__link links__link--blue">
                        <div className="links__inner">
                            <div className="links__icon">
                                <FontAwesomeIcon icon={faCommentDots} />
                            </div>
                            <div className="links__question">
                                Want to hear what some of our customers have had
                                to say about us?
                            </div>
                            <div className="links__button-container">
                                <Button
                                    text="Testimonials"
                                    to="testimonials"
                                    theme="grey"
                                />
                            </div>
                        </div>
                    </div>
                </PageSection>
            </div>
        </Layout>
    );
};
export default IndexPage;
