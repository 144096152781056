import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './button.scss';

const Button = ({ className, text, to, theme, isNavigation, onClickHandler }) =>
    isNavigation ? (
        <Link to={`/${to}`} className={`button button--${theme} ${className}`}>
            {text}
        </Link>
    ) : (
        <button
            type="button"
            className={`button button--${theme} ${className}`}
            onClick={onClickHandler}
        >
            {text}
        </button>
    );

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    theme: PropTypes.string,
    isNavigation: PropTypes.bool,
    onClickHandler: PropTypes.func,
};

Button.defaultProps = {
    className: '',
    theme: 'blue',
    isNavigation: true,
    onClickHandler: () => {},
};

export default Button;
